import axios from 'axios';
var load = require('load-script');
import Inputmask from "inputmask";

class ContactForms {
	constructor() {
		this.$forms 	= document.querySelectorAll('[data-form]');

		this.init();
	}

	init() {
		var _this = this;

		var ready = new Promise((resolve) => {
			load('https://www.google.com/recaptcha/api.js?render=6Lc-R-chAAAAAJ1X-MyIW4N5IYvXAVzcGabK38rb', function (err, script) {
				resolve();
			})
		});

		ready.then(() => {
			_this.$forms.forEach(($form, i) => {
				_this[i] = new ContactForm($form);
			});
		});
	}
};

class ContactForm {
	constructor($form) {
		this.$form = $form;
		this.$message = null;
		this.action = this.$form.getAttribute("action");

		if (this.$form.length > 0) {
			this.init();
		}
	}

	init() {
		var _this = this;

		var phoneMask = new Inputmask("999-999-9999");
		phoneMask.mask(_this.$form.querySelector('[name="phone"]'));

		var emailMask = new Inputmask("*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}.*{2,6}");
		emailMask.mask(_this.$form.querySelector('[name="email"]'));

		this.$message = this.$form.parentNode.querySelector('[data-message]');

		_this.$form.addEventListener('submit', (e) => {
			e.preventDefault();

			let message = '';
			Array.from(e.target.elements).forEach(element => {
				if (element.type != 'submit' && element.name != 'recipient') {
					message += element.dataset['label'] + ': ';
					if (element.type == 'checkbox' || element.type == 'radio') {
						message += element.checked + '\n';
					} else {
						message += element.value + '\n';
					}
				}
			});
			message += "Page: " + window.location.href;

			var data = {
				from: e.target.elements['email'].value,
				to: [e.target.elements['recipient'].value],
				subject: "New Contact Form Submission",
				Body: message,
				isBodyHtml: false
			};
			console.log(e.target.elements);

			var captchaPass = false;
			var submitPromise = new Promise(resolve => {
				grecaptcha.ready(function () {
					grecaptcha.execute('6Lc-R-chAAAAAJ1X-MyIW4N5IYvXAVzcGabK38rb', { action: 'submit' }).then(function (token) {
						captchaPass = true;
					});
				});

				axios.post(_this.action, data)
					.then(function (response) {
						console.log(response);

						console.log(e.target.elements['subscribe'].checked);
						if (e.target.elements['subscribe'].checked) {
							let request = { 
								first	: e.target.elements['first-name'].value,
								last	: e.target.elements['last-name'].value,
								company	: e.target.elements['company'].value,
								email	: e.target.elements['email'].value,
								phone	: e.target.elements['phone'].value,
							}

							if (typeof e.target.elements['referral-url'] !== 'undefined') {
								request.referralUrl = e.target.elements['referral-url'].value;
							} else {
								request.referralUrl = '';
							}

							if (typeof e.target.elements['service-line'] !== 'undefined') {
								request.serviceLine = e.target.elements['service-line'].value;
							} else {
								request.serviceLine = '';
							}

							if (typeof e.target.elements['sign-up-type'] !== 'undefined') {
								request.signUpType = e.target.elements['sign-up-type'].value;
							} else {
								request.signUpType = '';
							}

							axios.post('/umbraco/api/newslettersignup/send', request)
								.then(function (response) {
									console.log(response);
									resolve();
								})
								.catch(function (error) {
									console.log(error);
								});
						} else {
							resolve();
						}
					})
					.catch(function (error) {
						console.log(error);
					});

			});

			submitPromise.then(() => {
				Object.keys(e.target.elements).forEach((element) => {
					var $el = e.target.elements[element];
					if ($el.getAttribute('type') != 'hidden') {
						$el.value = '';
					}
				});
				_this.$message.style.display = 'block';
            });

			return false;
		});
	}
}

export default ContactForms