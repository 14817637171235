class Filters {
	constructor() {
		this.$trigger 	= document.querySelector('[data-filter-trigger]');

		if(this.$trigger != null) {
			this.$wrapper 	= this.$trigger.parentElement;
			this.state 		= 'closed';
			
			this.init();
		}
	}

	init() {
		var _this = this;

		_this.$trigger.addEventListener('click', (e) => {
			e.stopPropagation();

			if(_this.state == 'closed') {
				_this.state = 'open'
				_this.$wrapper.classList.add('filter-open');
			} else {
				_this.state = 'closed';
				_this.$wrapper.classList.remove('filter-open');
			}
		});
		_this.$wrapper.addEventListener('click', (e) => {
			e.stopPropagation();
		});

		document.body.addEventListener('click', (e) => {
			if(_this.state == 'open') {
				_this.state = 'closed';
				_this.$wrapper.classList.remove('filter-open');
			}
		});
	}
};

export default Filters