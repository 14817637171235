import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class ImageLoading {
	constructor(NG) {
		this.$images = [];
		this.$imgs = [];
	}

	init(NG) {
		var _this = this;

		_this.$images = [];
		_this.$imgs = [];

		_this.$images = document.querySelectorAll('[data-lazyload]');
		_this.$images.forEach($image => {
			_this.$imgs.push($image.querySelector('img'));
		});

		_this.setBg(NG.themeDefaults.options[NG.themeDefaults.active].figure);

		_this.lazyLoad(_this.$imgs);
	}
		
	setBg(color) {
		var _this = this;
		
		var codedColor = color.replace('#','%23');

		_this.$images.forEach($image => {
			$image.style.background = 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'30\' height=\'30\'><line stroke-width=\'1\' stroke=\'' + codedColor + '\' x1=\'0\' y1=\'30\' x2=\'30\' y2=\'0\'/><line stroke-width=\'1\' stroke=\'' + codedColor + '\' x1=\'0\' y1=\'60\' x2=\'60\' y2=\'0\'/><line stroke-width=\'1\' stroke=\'' + codedColor + '\' x1=\'-30\' y1=\'30\' x2=\'30\' y2=\'-30\'/></svg>")';
		});
	}

	lazyLoad($imgs) {
		var _this = this;

		$imgs.forEach($img => {
			$img.setAttribute('src', $img.dataset.src);
			
			var tl = gsap.timeline({
				scrollTrigger: {
					duration: 0,
					trigger: $img
				},
				onStart() {
					if ($img.complete) {
						console.log('complete');
						_this.tweenIn($img);
					} else {
						console.log('listening');
						$img.addEventListener('load', ($img) => {_this.tweenIn})
					}
				}
			});
			
			tl.to($img, { opacity: 1 });
		});
	}

	tweenIn($img) {
		$img.style.opacity = 1;
	}
}

export default ImageLoading