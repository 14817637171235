import Vue from 'vue';
import axios from 'axios';
import queryString from 'query-string';

class ResourcesSearch {
	constructor() {
		this.init();
	}

	init() {
		var _this = this;

		var resourcesList = Vue.component('resources-list', {
			props: ['resources','resourceType','filteredResources','pagedResources'],
			data: function () {
				return {
					//resources: this.resources
				}
			},
			mounted() {
				var _this = this;
			},
			template: `
			<div class="resources-list-wrapper">
				<div class="number-found"><span class="number">{{filteredResources.length}}</span> Resources Found</div>
				<div class="resources-list">
					<article class="resource" v-for="resource in pagedResources()">
						<div class="image" v-if="resource.image">
							<a :href="'/resources/' + resource.url"><img :src="resource.image + '?width=800&quality=80'" /></a>
						</div>
						<div class="content">
							<div class="type">{{resource.resourceType.name}}</div>
							<h2 class="heading h4">
								<a :href="'/resources/' + resource.url">{{resource.resourceName}}</a>
							</h2>
							<!--<p>{{resource.summary}}</p>-->
							<a :href="'/resources/' + resource.url" class="cta">Go to resource</a>
						</div>
					</article>
				</div>
			</div>
			`
		})

		var filterByType = Vue.component('filter-by-type', {
			props: ['resources','resourceType','filteredResources'],
			data: function () {
				return {
					//resources: this.resources
					resourceTypeFilter	: this.resourceType,
					resourceQuery		: this.resourceSearch,
					types				: {
						news		: {
								value	: 'news-article',
								label	: 'News'
							},
						blogPost	: {
								value	: 'blog-entry',
								label	: 'Blog entry'
							},
						event		: {
								value	: 'event',
								label	: 'Event'
							},
						report		: {
								value	: 'report',
								label	: 'Report'
							},
						faq			:{
								value	: 'faq-resource',
								label	: 'FAQ'
							},
						tips		: {
								value	: 'tips',
								label	: 'Tips'
							},
						video		: {
								value	: 'video',
								label	: 'Video'
							},
						webinar		: {
								value	: 'webinar',
								label	: 'Webinar'
							},
					}
				}
			},
			mounted() {
				var _this = this;
			},
			watch: {
				resourceType() {
					this.resourceTypeFilter = this.resourceType;
					this.onTypeClicked();
				}
			},
			methods: {
				onTypeChanged(val) {
					const _this = this

					if (typeof val !== 'undefined') {
						this.resourceTypeFilter = val;
						this.$emit('type-changed', val);
					} else {
						this.$emit('type-changed', this.resourceTypeFilter);
					}

					return false;
				},
				onTypeClicked(value) {
					const _this = this;

					if (this.resourceTypeFilter == value) {
						this.resourceTypeFilter = [];
						this.onTypeChanged();
					}
					//this.$emit('type-changed', this.advisorTypeFilter);

					return false;
				},
				onQueryChanged() {
					const _this = this
					this.$emit('query-changed', this.resourceQuery);

					return false;
				},
				clearSearch() {
					this.resourceQuery = '';
					this.$emit('query-changed', this.resourceQuery);
				}
			},
			template: `
				<div class="filter-by-type">
					<h3 class="heading">
						Filter by Content Type
						<div class="clear-type" v-if="resourceTypeFilter != [] && resourceTypeFilter != ''" v-on:click="onTypeChanged([])">&times; Clear Type Selection</div>
					</h3>
					<div class="types">
						<div class="type" v-for="type in types">
							<label><input type="checkbox" :name="type.value" :value="type.value" v-on:change="onTypeChanged()" v-on:click="onTypeClicked(type.value)" v-model="resourceTypeFilter" /><div class="tag">{{type.label}}</div></label>
						</div>
					</div>
					<div class="label">Type</div>
					<div class="field select type-select">
						<div class="select-input">
							<select v-model="resourceTypeFilter">
								<option value="">none</option>
								<option :value="type.value" v-for="type in types">{{type.label}}</option>
							</select>
						</div>
					</div>
					<div class="search">
						<input type="text" placeholder="Filter by Keyword" v-model="resourceQuery" v-on:keyup="onQueryChanged()">
						<div class="clear" v-if="resourceQuery != null && resourceQuery != ''" v-on:click="clearSearch()">&times;</div>
					</div>
				</div>
			`
		})

		var filterByCat = Vue.component('filter-by-cat', {
			props: ['resources', 'resourceSolution', 'resourceIndustry', 'resourceTopic','filteredResources'],
			data: function () {
				return {
					//resources: this.resources
					resourceSolutionFilter: this.resourceSolution,
					resourceIndustryFilter: this.resourceIndustry,
					resourceTopicFilter: this.resourceTopic,
				}
			},
			mounted() {
				var _this = this;
			},
			watch: {
				resourceSolution() {
					this.resourceSolutionFilter = this.resourceSolution;
					this.onSolutionChanged();
				},
				resourceIndustry() {
					this.resourceIndustryFilter = this.resourceIndustry;
					this.onIndustryChanged();
				},
				resourceTopic() {
					this.resourceTopicFilter = this.resourceTopic;
					this.onTopicChanged();
				}
			},
			filters: {
				replace: function (st, rep, repWith) {
					const result = st.split(rep).join(repWith)
					return result;
				}
			},
			methods: {
				onSolutionChanged(val) {
					const _this = this

					if(typeof val !== 'undefined') {
						this.resourceSolutionFilter = val;
						this.$emit('solution-changed', val);
					} else {
						this.$emit('solution-changed', this.resourceSolutionFilter);
					}

					return false;
				},
				onIndustryChanged(val) {
					const _this = this

					if(typeof val !== 'undefined') {
						this.resourceIndustryFilter = val;
						this.$emit('industry-changed', val);
					} else {
						this.$emit('industry-changed', this.resourceIndustryFilter);
					}

					return false;
				},
				onTopicChanged(val) {
					const _this = this

					if(typeof val !== 'undefined') {
						this.resourceTopicFilter = val;
						this.$emit('topic-changed', val);
					} else {
						this.$emit('topic-changed', this.resourceTopicFilter);
					}

					return false;
				},
				clearSelections() {
					this.onSolutionChanged([]);
					this.onIndustryChanged([]);
					this.onTopicChanged([]);
				}
			},
			template: `
				<div class="col filter-by-cat-col">
					<header class="header" data-filter-trigger>
						<h3 class="heading">Filter by Categories</h3>
						<div class="clear-solution" v-on:click="clearSelections()" v-if="resourceSolutionFilter.length || resourceIndustryFilter.length || resourceTopicFilter.length">&times; Clear Selections</div>
						<div class="label" v-if="resourceSolutionFilter.length">Solution: <span v-for="(solution, index) in resourceSolutionFilter"><span class="separator" v-if="index != 0">, </span>{{solution | replace('-',' ')}}</span></div>
						<div class="label" v-if="resourceIndustryFilter.length">Industry: <span v-for="(industry, index) in resourceIndustryFilter"><span class="separator" v-if="index != 0">, </span>{{industry | replace('-',' ')}}</span></div>
						<div class="label" v-if="resourceTopicFilter.length">Topic: <span v-for="(topic, index) in resourceTopicFilter"><span class="separator" v-if="index != 0">, </span>{{topic | replace('-',' ')}}</span></div>
					</header>
					<section class="cat-group">
						<h4 class="heading">Solution</h4>
						<div class="field">
							<label><input type="checkbox" name="personal-insurance" value="personal-insurance" v-on:change="onSolutionChanged()" v-model="resourceSolutionFilter" /><div class="radio-button"></div><span class="text">Personal Insurance</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="business-insurance" value="business-insurance" v-on:change="onSolutionChanged()" v-model="resourceSolutionFilter" /><div class="radio-button"></div><span class="text">Business Insurance</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="employee-benefits" value="employee-benefits" v-on:change="onSolutionChanged()" v-model="resourceSolutionFilter" /><div class="radio-button"></div><span class="text">Employee Benefits</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="general" value="general" v-on:change="onSolutionChanged()" v-model="resourceSolutionFilter" /><div class="radio-button"></div><span class="text">General</span></label>
						</div>
					</section>
					<section class="cat-group" v-if="resourceSolutionFilter.indexOf('business-insurance') > -1">
						<h4 class="heading">Industry</h4>
						<div class="field">
							<label><input type="checkbox" name="manufacturing" value="manufacturing" v-on:change="onIndustryChanged()" v-model="resourceIndustryFilter" /><div class="radio-button"></div><span class="text">Manufacturing</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="transportation" value="transportation" v-on:change="onIndustryChanged()" v-model="resourceIndustryFilter" /><div class="radio-button"></div><span class="text">Transporation</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="construction" value="construction" v-on:change="onIndustryChanged()" v-model="resourceIndustryFilter" /><div class="radio-button"></div><span class="text">Construction</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="retail" value="retail" v-on:change="onIndustryChanged()" v-model="resourceIndustryFilter" /><div class="radio-button"></div><span class="text">Retail</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="farming" value="farming" v-on:change="onIndustryChanged()" v-model="resourceIndustryFilter" /><div class="radio-button"></div><span class="text">Farming</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="tech-sector" value="tech-sector" v-on:change="onIndustryChanged()" v-model="resourceIndustryFilter" /><div class="radio-button"></div><span class="text">Tech Sector</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="other-industries" value="other-industries" v-on:change="onIndustryChanged()" v-model="resourceIndustryFilter" /><div class="radio-button"></div><span class="text">Other Industries</span></label>
						</div>
					</section>
					<section class="cat-group">
						<h4 class="heading">Topic</h4>
						<div class="field">
							<label><input type="checkbox" name="awards" value="awards" v-on:change="onTopicChanged()" v-model="resourceTopicFilter" /><div class="radio-button"></div><span class="text">Awards</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="business" value="business" v-on:change="onTopicChanged()" v-model="resourceTopicFilter" /><div class="radio-button"></div><span class="text">Business</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="compliance" value="compliance" v-on:change="onTopicChanged()" v-model="resourceTopicFilter" /><div class="radio-button"></div><span class="text">Compliance</span></label>
						</div>
						<div class="field">
							<label><input type="checkbox" name="workers-comp" value="workers-comp" v-on:change="onTopicChanged()" v-model="resourceTopicFilter" /><div class="radio-button"></div><span class="text">Workers' Compensation</span></label>
						</div>
					</section>
				</div>
			`
		})

		var pagination = Vue.component('pagination', {
			props: ['filteredResources', 'pageLength', 'nextPage', 'curPage'],
			data: function () {
				return {
				}
			},
			mounted() {
				var _this = this;
			},
			template: `
				<section class="component pagination" data-transition v-if="filteredResources.length > pageLength">
					<div class="back-next-nav">
						<a class="back-next back" v-on:click="nextPage(curPage-1)" v-if="curPage > 0">Back</a>
					</div>
					<ul class="page-list"> 
						<li class="page-item" v-bind:class="{active:curPage == i}" v-for="(page, i) in Math.ceil(filteredResources.length/pageLength)" v-if="i > curPage - 3 && i < curPage + 3">
							<a class="page-link" v-on:click="nextPage(i)">{{i+1}}</a>
						</li>
					</ul>
					<div class="back-next-nav">
						<a class="back-next next" v-on:click="nextPage(curPage+1)" v-if="curPage+1 < Math.ceil(filteredResources.length/pageLength)">Next</a>
					</div>
				</section>
			`
		})

		const app = new Vue({
			el: '#resources',
			components: { resourcesList, filterByType, filterByCat, pagination},
			data: {
				resources: [],
				filteredResources: [],
				resourceType: [],
				resourceSolution: [],
				resourceIndustry: [],
				resourceTopic: [],
				pageLength: 9,
				curPage: 0,
			},
			mounted() {
				var _this = this;

				var resourcesPromise = new Promise((resolve) => {
					_this.getResources(resolve);
				});

				resourcesPromise.then((data) => {
					this.resources = data;
					this.filteredResources = data;
				});

				if (typeof queryString.parse(location.search).type !== 'undefined') {
					this.updateType(queryString.parse(location.search).type.split(','));
				}
				if (typeof queryString.parse(location.search).solution !== 'undefined') {
					this.updateSolution(queryString.parse(location.search).solution.split(','));
				}
				if (typeof queryString.parse(location.search).industry !== 'undefined') {
					this.updateIndustry(queryString.parse(location.search).industry.split(','));
				}
				if (typeof queryString.parse(location.search).topic !== 'undefined') {
					this.updateTopic(queryString.parse(location.search).topic.split(','));
				}
			},
			methods: {
				getResources(resolve) {
					axios({
						method: "get",
						url: "/umbraco/api/resources/getResources",
						headers: { "Content-Type": "application/x-www-form-urlencoded; charset=utf-8" },
					})
					.then(function (response) {
						//handle success
						resolve(response.data);
					})
					.catch(function (response) {
						//handle error
						console.log(response);
					});
				},

				updateType(resourceType) {
					this.setQuery('type', resourceType);
					this.resourceType = resourceType;

					this.filterResources();
				},
				updateSolution(resourceSolution) {
					this.setQuery('solution', resourceSolution);
					this.resourceSolution = resourceSolution;

					this.filterResources();
				},
				updateIndustry(resourceIndustry) {
					this.setQuery('industry', resourceIndustry);
					this.resourceIndustry = resourceIndustry;

					this.filterResources();
				},
				updateTopic(resourceTopic) {
					this.setQuery('topic', resourceTopic);
					this.resourceTopic = resourceTopic;

					this.filterResources();
				},
				updateSearch(resourceSearch) {
					this.resourceSearch = resourceSearch;

					this.filterResources();
				},
				filterResources() {
					var _this = this;

					var _filtered;

					var resourcesPromise = new Promise((resolve) => {
						_this.getResources(resolve);
					});

					resourcesPromise.then((data) => {
						_filtered = data;

						//Filter by Type
						if (this.resourceType != '') {
							_filtered = _filtered.filter(resource => this.resourceType.indexOf(resource.resourceType.alias.toLowerCase().replaceAll(' ', '-')) > -1 );
						}

						//Filter by Solution
						if (this.resourceSolution != '') {
							_filtered = _filtered.filter(resource => this.resourceSolution.some(x =>
								resource.solutions.toLowerCase().replaceAll(' ', '-').includes(x)
							));
						}

						//Filter by Industry
						if (this.resourceSolution.indexOf('business-insurance') > -1 && this.resourceIndustrys != '' && typeof this.resourceIndustrys !== 'undefined') {
							_filtered = _filtered.filter(resource => this.resourceIndustry.indexOf(resource.industrys.toLowerCase().replaceAll(' ', '-')) > -1);
						}

						//Filter by Topic
						if (this.resourceTopic != '' && typeof this.resourceTopic !== 'undefined') {
							_filtered = _filtered.filter(resource => this.resourceTopic.indexOf(resource.topics.toLowerCase().replaceAll(' ', '-')));
						}

						//Filter by Search
						if (this.resourceSearch != '' && typeof this.resourceSearch !== 'undefined') {
							_filtered = _filtered.filter(resource => resource.resourceName.toLowerCase().replaceAll(' ', '-').includes(this.resourceSearch));
						}

						this.filteredResources = _filtered;
					});
				},
				nextPage(page) {
					let _next = page == this.filteredResources.length ? 0 : page;
					_next = page < 0 ? this.filteredResources.length - 1 : page;

					this.curPage = page;
				},
				pagedResources() {
					const _this = this;

					let _paged = [];
					return this.filteredResources.slice(this.curPage * this.pageLength, this.curPage * this.pageLength + this.pageLength)
				},
				setQuery(name, value) {
					const params = new URLSearchParams(window.location.search);
					if (value != '') {
						params.set(name, value);
					} else {
						params.delete(name);
					}
					window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));
				}
			},
			computed: {
			},
		})
	}
}

export default ResourcesSearch