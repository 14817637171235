import Vue from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';
import Inputmask from "inputmask";

class ResourceDetail {
	constructor() {
		this.init();
	}

	init() {
		var _this = this;

		const app = new Vue({
			el: '#resource',
			data: {
				gated: true,
				authed: false,
				notAllowed: false,
				resourceAlias: "",
				grantedResources: []
			},
			mounted() {
				var self = this;

				this.getGrantResources();
				
				this.resourceAlias = this.$el.attributes['data-resource-alias'].value;
				this.gated = this.$el.attributes['data-is-gated'].value == 'True';

				if (!this.gated || this.grantedResources.indexOf(self.resourceAlias) > -1) {
					this.authed = true;
				}

				var phoneMask = new Inputmask("999-999-9999");
				phoneMask.mask(self.$el.querySelector('[name="resource-phone"]'));
			},
			methods: {
				getGrantResources() {
					try {
						JSON.parse(str);
					} catch (e) {
						return false;
					}
					this.grantedResources = Cookies.get('granted') ? JSON.parse(Cookies.get('granted')) : [];
					return false;
				},
				requestResource(e) {
					let isValidEmail = true;

					let request = {
						first: e.target.elements['resource-first-name'].value,
						last: e.target.elements['resource-last-name'].value,
						email: e.target.elements['resource-email'].value,
						phone: e.target.elements['resource-phone'].value,
						company: e.target.elements['resource-company'].value,
						serviceline: e.target.elements['service-line'].value
					}

					if (typeof this.resourceAlias !== 'undefined') {
						request.resourceName = this.resourceAlias;
					} else {
						request.resourceName = '';
					}

					if (typeof e.target.elements['referral-url'] !== 'undefined') {
						request.referralUrl = e.target.elements['referral-url'].value;
					} else {
						request.referralUrl = '';
					}

					if (typeof e.target.elements['sign-up-type'] !== 'undefined') {
						request.signUpType = e.target.elements['sign-up-type'].value;
					} else {
						request.signUpType= '';
					}
					console.log(request);

					const containsPhrase = phrase => request.email.includes(phrase);
					const emailBlacklist = [
						'vizance.com',
						'robertsonryan.com',
						'm3ins.com', 
						'associatebenefitsgroup.com',
						'mcclone.com'
					];
					if (emailBlacklist.some(containsPhrase)) {
						isValidEmail = false;
					}

					if (isValidEmail) {
						this.sendRequest(request, this.resourceAlias);
					} else {
						this.onNotAllowed();
                    }
				},
				sendRequest(request, alias) {
					var self = this;

					axios.post('/umbraco/api/resourceauth/sendrequest', request)
						.then(function (response) {
							console.log(response);
							self.allowAccess(self.resourceAlias);
						})
						.catch(function (error) {
							console.log(error);
							self.allowAccess(self.resourceAlias);
						});
				},
				onNotAllowed() {
					this.notAllowed = true;
					Cookies.set('notAllowed', true);
					console.log('not allowed');
				},
				allowAccess(resourceAlias) {
					var self = this;

					if (self.grantedResources.indexOf(resourceAlias < 0)) {
						self.grantedResources.push(resourceAlias);
                    }

					Cookies.set('granted', JSON.stringify(self.grantedResources));
					console.log('granted');

					this.authed = true;
				},
			},
		})
	}
}

export default ResourceDetail