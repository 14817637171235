import Vue from 'vue';
import axios from 'axios';
import queryString from 'query-string'

class Search {
	constructor() {
		this.init();
	}

	init() {
		var _this = this;

		var searchResults = Vue.component('search-results', {
			props: ['query', 'results', 'pageLength', 'curPagesPage', 'curResourcesPage'],
			mounted() {
				var _this = this;
			},
			methods: {
				nextPagesPage(collection, page) {
					let _next = page == collection.length ? 0 : page;
					_next = page < 0 ? collection.length - 1 : page;

					this.curPagesPage = _next;
					console.log(this.curPagesPage, page);
				},
				nextResourcesPage(collection, page) {
					let _next = page == collection.length ? 0 : page;
					_next = page < 0 ? collection.length - 1 : page;

					this.curResourcesPage = _next;
					console.log(this.curPagesPage, page);
				},
				pagedNodes(nodes,cur,length) {
					const _this = this;

					let _paged = [];
					return nodes.slice(cur * length, cur * length + length)
				},
			},
			template: `
			<section class="search-results-list">
				<p v-if="results.allNodes.length > 0">Showing {{results.allNodes.length}} results for "{{query}}"</p>

				<section v-if="results.pagesNodes.length > 0">
					<h2>{{results.pagesNodes.length}} Pages</h2>
					<div class="component items">
						<article class="item" v-for="(block, index) in pagedNodes(results.pagesNodes,curPagesPage,pageLength)">
							<div class="content">
								<a :href="block.url" class="h4 link">{{block.name}}</a>
								<p>{{block.description}}</p>
							</div>
						</article>
					</div>
					<pagesPagination :pages-nodes="results.pagesNodes" :page-length="pageLength" :cur-page="curPagesPage" :next-page="nextPagesPage"></pagesPagination>
				</section>


				<section v-if="results.resourcesNodes.length > 0">
					<h2>{{results.resourcesNodes.length}} Resources</h2>
					<div class="component items">
						<article class="item" v-for="(block, index) in pagedNodes(results.resourcesNodes,curResourcesPage,pageLength)">
							<div class="content">
								<a :href="block.url" class="h4 link">{{block.name}}</a>
								<p>{{block.description}}</p>
							</div>
						</article>
					</div>
					<resourcesPagination :resources-nodes="results.resourcesNodes" :page-length="pageLength" :cur-page="curResourcesPage" :next-page="nextResourcesPage"></resourcesPagination>
				</section>

				<div class="component no-results" v-if="results.allNodes.length == 0 && (query != '' && query != null)">
					<h3>No results found for "{{query}}"</h3>
				</div>
				<div class="component no-results" v-if="query == '' || query == null">
					<h3>Enter a search</h3>
				</div>
			</section>
			`
		})

		var searchForm = Vue.component('search-form', {
			props: ['query', 'setQuery'],
			data: function () {
				return {
					searchQuery: this.query,
				}
			},
			mounted() {
				var _this = this;
			},
			methods: {
				onQueryChanged() {
					const _this = this;

					this.$emit('query-changed', this.searchQuery);

					return false;
				},
				clearQuery() {
					this.searchQuery = '';
					this.onQueryChanged()
				}
			},
			template: `
				<form action="/search" method="get">
					<div class="search">
						<div class="search-wrapper">
							<div class="search-trigger" id="search-trigger">
								<svg class="icon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<title>Search</title>
									<path d="M14.8651 13.4719L14.2548 14.2523L13.4743 14.8624C12.1114 15.9279 10.3978 16.5608 8.53187 16.5608C4.0949 16.5608 0.5 12.9664 0.5 8.53042C0.5 4.0944 4.0949 0.5 8.53187 0.5C12.9689 0.5 16.5637 4.0944 16.5637 8.53042C16.5637 10.396 15.9307 12.1092 14.8651 13.4719ZM14.2823 15.2563V14.2799H15.2589H15.9275C16.0593 14.2799 16.1818 14.3311 16.2713 14.4206L20.359 18.5075C20.3593 18.5078 20.3596 18.5081 20.3599 18.5084C20.5474 18.6989 20.5471 19.0064 20.3568 19.1967L19.1959 20.3573L19.1941 20.3592C19.0093 20.5459 18.7038 20.5486 18.5125 20.3573L14.4229 16.2684C14.3334 16.1789 14.2823 16.0565 14.2823 15.9248V15.2563ZM2.78149 8.53042C2.78149 11.7105 5.35605 14.2799 8.53187 14.2799C11.7123 14.2799 14.2823 11.7059 14.2823 8.53042C14.2823 5.35032 11.7077 2.78093 8.53187 2.78093C5.35144 2.78093 2.78149 5.35493 2.78149 8.53042Z" fill="#22426F" stroke="white" />
								</svg>
							</div>
							<div class="field text">
								<input type="text" v-model="searchQuery" name="q" v-on:keyup="onQueryChanged()" />
							</div>
							<div class="clear" v-if="searchQuery != null && searchQuery != ''" v-on:click="clearQuery('')">&times;</div>
						</div>
					</div>
				</form>
			`
		});

		var pagesPagination = Vue.component('pagesPagination', {
			props: ['pagesNodes', 'pageLength', 'nextPage', 'curPage'],
			data: function () {
				return {
				}
			},
			mounted() {
				var _this = this;
			},
			template: `
				<section class="component pagination" data-transition v-if="pagesNodes.length > pageLength">
					<div class="back-next-nav">
						<a class="back-next back" v-on:click="nextPage(pagesNodes,curPage-1)" v-if="curPage > 0">Back</a>
					</div>
					<ul class="page-list"> 
						<li class="page-item" v-bind:class="{active:curPage == i}" v-for="(page, i) in Math.ceil(pagesNodes.length/pageLength)" v-if="i > curPage - 3 && i < curPage + 3">
							<a class="page-link" v-on:click="nextPage(pagesNodes,i)">{{i+1}}</a>
						</li>
					</ul>
					<div class="back-next-nav">
						<a class="back-next next" v-on:click="nextPage(pagesNodes,curPage+1)" v-if="curPage+1 < Math.ceil(pagesNodes.length/pageLength)">Next</a>
					</div>
				</section>
			`
		});

		var resourcesPagination = Vue.component('resourcesPagination', {
			props: ['resourcesNodes', 'pageLength', 'nextPage', 'curPage'],
			data: function () {
				return {
				}
			},
			mounted() {
				var _this = this;
			},
			template: `
				<section class="component pagination" data-transition v-if="resourcesNodes.length > pageLength">
					<div class="back-next-nav">
						<a class="back-next back" v-on:click="nextPage(resourcesNodes,curPage-1)" v-if="curPage > 0">Back</a>
					</div>
					<ul class="page-list"> 
						<li class="page-item" v-bind:class="{active:curPage == i}" v-for="(page, i) in Math.ceil(resourcesNodes.length/pageLength)" v-if="i > curPage - 3 && i < curPage + 3">
							<a class="page-link" v-on:click="nextPage(resourcesNodes,i)">{{i+1}}</a>
						</li>
					</ul>
					<div class="back-next-nav">
						<a class="back-next next" v-on:click="nextPage(resourcesNodes,curPage+1)" v-if="curPage+1 < Math.ceil(resourcesNodes.length/pageLength)">Next</a>
					</div>
				</section>
			`
		});

		const app = new Vue({
			el: '#search-results',
			components: {searchResults,searchForm,pagesPagination},
			data: {
				query: queryString.parse(location.search).q,
				results: {
					allNodes: [],
					pagesNodes: [],
					resourcesNodes: [],
				},
				pageLength: 9,
				curPagesPage: 0,
				curResourcesPage: 0
			},
			mounted() {
				var _this = this;

				_this.setQuery(this.query);
			},
			methods: {
				getResults(query) {

					var resultsPromise = new Promise((resolve) => {
						axios({
							method: "get",
							url: "/searchresults/" + query,
							headers: { "Content-Type": "application/x-www-form-urlencoded; charset=utf-8" },
						})
							.then(function (response) {
								//handle success
								resolve(response.data);
							})
							.catch(function (response) {
								//handle error
								console.log(response);
							});
					});

					resultsPromise.then((data) => {
						this.results = data;
					});
				},

				setQuery(query) {
					var _this = this;

					_this.query = query;
					_this.getResults(query);
				},
			}
		})
	}
}

export default Search