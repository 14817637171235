import { tns } from "tiny-slider"

class Sliders {
	constructor() {
		this.$wrappers 	= document.querySelectorAll('[data-slider-wrapper]');
		this.sliders 	= [];
		
		this.init();
	}

	init() {
		var _this = this;

		_this.$wrappers.forEach(($wrapper, i) => {
			var $slider = $wrapper.querySelector('[data-slider]');

			//Initialize slider object
			_this.sliders[i] = {
				$slider		: $slider,
				active		: 0,
				slidesCount	: $slider.children.length,
			};

			//Build slider
			_this.sliders[i].slider = _this.addSlider(_this.sliders[i], $wrapper);
		});
	}

	addSlider(slider,$wrapper) {
		//Assemble slider object
		var slider = tns({ //https://github.com/ganlanyuan/tiny-slider
			container							: slider.$slider,
			autoplay							: false,
			autoplayButtonOutput	: false,
			controls							: true,
			gutter								: 20,
			items									: 1,
			loop									: false,
			mode									: 'carousel',
			mouseDrag							: true,
			nav										: false,
			nextButton						: $wrapper.querySelector('[data-slider-nav-next]'),
			prevButton						: $wrapper.querySelector('[data-slider-nav-back]'),
			slideBy								: 1,
			speed									: 600,
			responsive: {
				500: {
					gutter		: 72,
					items			: 2,
					slideBy		: 2,
				},
				800: {
					gutter		: 72,
					items			: 3,
					slideBy		: 3,
				},
				1000: {
					gutter		: 72,
					items			: 4,
					slideBy		: 4,
				},
			}
		});

		return slider;
	}
};

export default Sliders