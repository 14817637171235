import Cookies from 'js-cookie';

class ThemeDefaults {
	constructor(NG) {
		this.default 	= 'dark';
		this.system 	= this.getThemePrefs();
		this.stored 	= Cookies.get('theme');
		this.active 	= this.default;

		//Establish all theme appearances
		this.options = {
			light	: {
				ground	: '#fff',
				ground2	: '#142640',
				figure	: '#22426f',
				figure2	: '#142640',
				link	: '#142640',
			},
			dark	: {
				ground	: '#22426f',
				ground2	: '#142640',
				figure	: '#fff',
				figure2	: '#ebf3ff',
				link	: '#ff9e18',
			}
		};

		this.init(NG);
	}

	init(NG) {
		var _this = this;

		//Set active to default
		_this.active = _this.default;

		//Set active to system UI settings
		//_this.active = _this.system;

		//Set active to stored setting
		_this.active = typeof _this.stored !== 'undefined' ? _this.stored : _this.active;
	}

	getThemePrefs(theme) {
		var _this = this;

		const query = window.matchMedia("(prefers-color-scheme: dark)");
		if (query.matches) {
			return 'dark';
		} else {
			return 'light';
		}
	}
}

export default ThemeDefaults