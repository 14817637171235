import Vue from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';

class NewsletterSignUp {
	constructor() {
		this.init();
	}

	init() {
		var _this = this;

		const $signUps = document.querySelectorAll('[data-newsletter-sign-up]');
		console.log($signUps);
		Array.prototype.forEach.call($signUps, ($el, index) => this.newApp($el))

	}

	newApp($el) {
		const app = new Vue({
			el: $el,
			data: {
				sent: false
			},
			mounted() {
				var self = this;
			},
			methods: {
				sendRequest(e, alias) {
					var self = this;

					let request = {
						email: e.target.elements['newsletter-email'].value
					}

					if (typeof e.target.elements['referral-url'] !== 'undefined') {
						request.referralUrl = e.target.elements['referral-url'].value;
					} else {
						request.referralUrl = '';
					}

					if (typeof e.target.elements['service-line'] !== 'undefined') {
						request.serviceLine = e.target.elements['service-line'].value;
					} else {
						request.serviceLine = '';
					}

					if (typeof e.target.elements['sign-up-type'] !== 'undefined') {
						request.signUpType = e.target.elements['sign-up-type'].value;
					} else {
						request.signUpType = '';
					}
					console.log(request);

					axios.post('/umbraco/api/newslettersignup/send', request)
						.then(function (response) {
							console.log(response);
							if (response.statusText == 'OK') {
								self.sent = true;
                            }
						})
						.catch(function (error) {
							console.log(error);
						});
				},
			},
		})
	}
}

export default NewsletterSignUp