import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class MobileMenu {
	constructor() {
		this.$trigger 			= document.querySelector('[data-menu-trigger]');
		this.$header 			= document.querySelector('[data-header]');
		this.state 				= 'closed';
		this.menuWidth	 		= 1220;
		this.mobileWidth	 	= 500;
		
		this.init();
	}

	init() {
		var _this = this;

		_this.$trigger.addEventListener('click', (e) => {
			e.stopPropagation();

			if(_this.state == 'closed') {
				_this.open();
			} else {
				_this.close();
			}
		});

		_this.onResize();
		window.addEventListener('resize', _this.onResize.bind(_this));
	}

	onResize() {
		var _this = this;
		console.log(_this.$header.offsetHeight);
		if (window.innerWidth < _this.menuWidth) {
			document.body.style.paddingTop = _this.$header.offsetHeight + 'px';
		} else {
			document.body.style.paddingTop = 0;
		}
	}

	open() {
		var _this = this;

		_this.state = 'open';
		document.body.classList.add('menu-open');

		gsap.to(_this.$header, {
			height: '100vh',
			duration: .4
		});
		gsap.to(window, {
			scrollTo: { y: 0 },
			duration: .2
		});
	}

	close() {
		var _this = this;

		_this.state = 'closed';
		document.body.classList.remove('menu-open');
		let _height = 0;
		console.log(window.innerWidth, _this);
		if (window.innerWidth < _this.mobileWidth) {
			_height = 114
		} else {
			_height = 119
		}

		gsap.to(_this.$header, {
			height: _height + 'px',
			duration: .2
		});
	}
};

export default MobileMenu