import '../styles/main.scss';
import './utilities';
import ThemeDefaults from './theme-defaults';
import Sliders from './slider';
import ThemeSwitcher from './theme-switcher';
import PageTransitions from './page-transitions';
import ImageLoading from './image-loading';
import ResourcesSearch from './resources-search';
import AdvisorsSearch from './advisors-search';
import SearchWidget from './search-widget';
import Search from './search';
import MobileMenu from './mobile-menu';
import Filters from './filters';
import Tabs from './tabs';
import Forms from './forms';
import ResourceDetail from './resource-detail';
import NewsletterSignUp from './newsletter-sign-up';
import ScrollAnchor from './scroll-anchor';
import Maps from './maps';


class Ansay {
	constructor() {
		this.mobileBreakpoint 	= 800;
		
		this.themeDefaults 			= new ThemeDefaults(this);
		this.imageLoading 			= new ImageLoading(this);
		//this.themeSwitcher 		= new ThemeSwitcher(this);
		this.pageTransitions 		= new PageTransitions(this);
		this.searchWidget			= new SearchWidget(this);
		this.mobileMenu				= new MobileMenu(this);
		//this.maps					= new Maps(this);
		
		this.init.bind(this)();
	}

	init() {
		var _this = this;

		_this.sliders 			= new Sliders();
		
		if (document.getElementById('resources') != null) {
			_this.resourcesSearch 	= new ResourcesSearch();
		}
		if(document.getElementById('advisors') != null) {
			_this.advisorsSearch 	= new AdvisorsSearch();
		}
		if (document.getElementById('resource') != null) {
			_this.resourceDetail	= new ResourceDetail(this);
		}
		
		_this.filters 			= new Filters();
		_this.search 			= new Search();
		_this.tabs 				= new Tabs();
		_this.forms				= new Forms(this);
		_this.newsletterSignUp	= new NewsletterSignUp(this);
		_this.scrollAnchor		= new ScrollAnchor(this);

		_this.imageLoading.init(_this);
		//_this.maps.init(_this);
	}
};

window.Ansay = new Ansay();