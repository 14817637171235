import { gsap } from 'gsap';

class SearchWidget {
	constructor() {
		this.$trigger 	= document.getElementById('search-trigger');
		this.$wrapper 	= this.$trigger.parentElement;
		this.$mainMav = this.$trigger.parentElement.parentElement.parentElement.parentElement;
		this.$field 	= this.$trigger.nextElementSibling;
		this.state 		= 'closed';
		
		this.init();
	}

	init() {
		var _this = this;

		_this.$trigger.addEventListener('click', (e) => {
			e.stopPropagation();

			if (_this.state == 'closed') {
				_this.open();
			} else {
				_this.close();
            }
		});
		_this.$wrapper.addEventListener('click', (e) => {
			e.stopPropagation();
		});

		document.body.addEventListener('click', (e) => {
			_this.close();
		});
	}

	open() {
		var _this = this;

		_this.state = 'open'
		_this.$mainMav.classList.add('open');
		gsap.to(_this.$field, {
			duration: .5,
			width: 300,
			ease: "elastic.out(.35, 0.3)"
		});
	}

	close() {
		var _this = this;

		if (_this.state == 'open') {
			_this.state = 'closed'
			gsap.to(_this.$field, {
				duration: .2,
				width: 0,
				onComplete: () => {
					_this.$mainMav.classList.remove('open');
				}
			});
		}
	}
};

export default SearchWidget