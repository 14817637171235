import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

class ScrollAnchor {
	constructor() {
		this.anchor = window.location.hash.replace('#','');
		this.$el = document.querySelectorAll('[data-anchor="' + this.anchor + '"]')[0];
		console.log(this.anchor);
		
		this.init();
	}

	init() {
		var self = this;

		if (typeof self.$el !== 'undefined') {
			setTimeout(() => {
				gsap.to(window, { duration: 1, scrollTo: { y: self.$el.getBoundingClientRect().top + document.documentElement.scrollTop } })
			}, 500);
        }
	}
};

export default ScrollAnchor