import barba from '@barba/core';
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import MobileMenu from './mobile-menu';
gsap.registerPlugin(ScrollToPlugin);

class PageTransitions {
	constructor(NG) {
		this.$sections 	= document.querySelectorAll('[data-transition]');

		this.init(NG);
	}

	init(NG) {
		var _this = this;

		_this.setPageName(document.getElementById('barba-container').dataset.barbaNamespace);
		barba.init({
			debug: true,
			timeout: 10000,
			transitions: [{
				name: 'default-transition',
				once(data) {
				},
				beforeLeave(data) {
					_this.$sections = document.querySelectorAll('[data-transition]');
				},
				leave(data) {
					return new Promise((resolve, reject) => {
						gsap.to(window, { duration: 1, scrollTo: 0 });
						NG.mobileMenu.close();

						//Init slide out timeline
						var tl = _this.fadeOut(_this.$sections, () => { resolve(); });

						//Begin tween out
						tl.play();
					});
				},
				enter(data) {
					NG.init();

					_this.$sections.forEach(($section) => {
						$section.remove();
					});

					_this.$sections = document.querySelectorAll('[data-transition]');
					gsap.set(_this.$sections, {
						alpha: 0
					});

					_this.setPageName(data.next.namespace);
				},
				after() {

					if (gtag == undefined || gtag == null) {
						console.log(">>>GTAG is not defined")
					} else {

						//gtag('config', GTAG_ID, {
						//	send_page_view: false,
						//});

						gtag('event', 'page_view', {
							'page_title': document.title,
							'page_location': location.href,
							'page_path': location.pathname,
						});
					}
					window.dataLayer.push({ 'event': 'barbaPageAfter' });
					

					var imagesLoaded = new Promise((resolve, reject) => {
						var $barbaContainer = document.querySelector('[data-barba]');
						var $imgs = $barbaContainer.querySelectorAll('img');
						var counter = 0;

						if ($imgs.length > 0) {
							[].forEach.call($imgs, function (img) {
								if (img.complete)
									incrementCounter();
								else
									img.addEventListener('load', incrementCounter, false);
							});
						} else {
							resolve();
                        }

						function incrementCounter() {
							counter++;
							if (counter === $imgs.length) {
								resolve();
							}
						}
					});
					imagesLoaded.then(() => {
						return new Promise((resolve, reject) => {
							var tl = _this.fadeIn(_this.$sections, () => {
								resolve();
							});
						});
					})
				}
			}]
		});
	}

	fadeOut($sections, callback) {
		var _this = this;

		var tl = gsap.timeline({
			paused		: true,
			onComplete	: () => { if(typeof callback !== 'undefined') { callback() } }
		});

		//Slide out
		tl.to($sections, {
			alpha		: 0, 
			duration	: .3,
			stagger		: .1
		});	

		return tl;
	}

	fadeIn($sections, callback) {
		var _this = this;

		var tl = gsap.timeline({});
						
		tl.to($sections, {
			alpha		: 1, 
			duration	: .3,
			stagger		: .1,
			onComplete	: () => { if(typeof callback !== 'undefined') { callback() } }
		});

		return tl;
	}

	setPageName(name) {
		document.getElementsByTagName('body')[0].dataset.page = name;
	}
}

export default PageTransitions