import { gsap } from 'gsap';

class Tabs {
	constructor() {
		this.$sets = document.querySelectorAll('[data-tabset]');

		this.init();
	}

	init() {
		var _this = this;

		_this.$sets.forEach((tabSet, i) => {
			_this[i] = new TabSet();
		});
	}
};

class TabSet {
	constructor() {
		this.cur = -1;

		this.$tabs = document.querySelectorAll('[data-tab]');
		this.$sections = document.querySelectorAll('[data-tab-section]');

		this.init();
	}

	init() {
		var _this = this;

		_this.changeSection(0);

		_this.$tabs.forEach((tab, i) => {
			tab.addEventListener('click', (e) => {
				e.preventDefault();

				_this.changeSection(i);
			});
		});
	}

	changeSection(next) {
		var _this = this;

		if (_this.cur != next) {
			const swapOut = new Promise((resolve, reject) => {
				if (_this.cur > -1) {
					_this.$tabs[_this.cur].classList.remove('active');
					gsap.to(_this.$sections[_this.cur], {
						duration: .2,
						alpha: 0,
						onComplete: () => {
							_this.$sections[_this.cur].style.display = 'none';
							resolve();
						}
					})
				} else {
					resolve();
				}
			});

			swapOut.then(() => {
				_this.cur = next;
				_this.$sections[_this.cur].style.display = 'block';
				gsap.to(_this.$sections[_this.cur], {
					duration: .2,
					alpha: 1
				});
				_this.$tabs[_this.cur].classList.add('active');
			});
		}
	}
}

export default Tabs